<template>
  <div class="footer">
    <div class="footer-information">
      <div class="footer-information-top">
        <div class="left-info">
          <div style="font-size: 1.6rem; margin-right: 80px">
            {{ FooterSetting.aboutUs }}
          </div>
          <div class="flex" style="font-size: 18px">
            0574-88903077&nbsp;&nbsp;|&nbsp;&nbsp;
            <div style="font-size: 1.2rem">{{ FooterSetting.place }}</div>
          </div>
        </div>
        <!-- <div class="right-button">
          <a-popover
            title=""
            v-for="(footerItem, index) in footerIcon"
            :key="index"
          >
            <template #content>
              <p
                style="text-align: center; font-size: 16px; font-weight: bolder"
              >
              </p>
              <img :src="footerItem.qr" class="QR-test" />
            </template>
            <div class="button-box">
              <img :src="footerItem.icon" class="" style="height: 28px" />
            </div>
          </a-popover>
        </div> -->
      </div>
      <div class="footer-information-bottom">
        <div
          class="footer-menu-box"
          v-for="(item, index) in FooterMenus"
          :key="index"
        >
          <div class="title" style="font-size: 20px">
            {{ `${item.title}`
            }}<span style="font-size: 14px">{{ `【${item.en}】` }}</span>
          </div>
          <div
            class="menu"
            v-for="(menu, i) in item.secList"
            :key="i"
            @click="changeRouter(menu, index)"
          >
            {{ `${menu.title} ${menu.en}` }}
          </div>
        </div>
      </div>
    </div>
    <div class="version">
      <a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="_blank"
        style="color: #939393"
        >版权所有：茗山智创小镇 | 浙ICP备2023022467号-1</a
      >
      <!-- <div>
        <img :src="filingIcon" style="float: left; margin-left: 20px" /><a
          href="http://www.beian.gov.cn/portal/index.do"
          target="_blank"
          style="
            float: left;
            height: 20px;
            line-height: 20px;
            margin: 0px 0px 0px 5px;
            color: #939393;
          "
          >桂公网安备 45010302002852号</a
        >
      </div> -->
    </div>
  </div>
</template>

<script>
import {
  WeiboOutlined,
  WechatOutlined,
  MobileFilled,
} from "@ant-design/icons-vue";
import { mapState } from "vuex";
export default {
  name: "Header",
  components: {
    WeiboOutlined,
    WechatOutlined,
    MobileFilled,
  },
  data() {
    return {
      footerIcon: [
        {
          icon: process.env.BASE_URL + "icons/icon-douyin.png",
          qr: process.env.BASE_URL + "qr/douyin.png",
        },
        {
          icon: process.env.BASE_URL + "icons/icon-weibo.png",
          qr: process.env.BASE_URL + "qr/weibo.png",
        },
        {
          icon: process.env.BASE_URL + "icons/icon-weixin.png",
          qr: process.env.BASE_URL + "qr/weixin.png",
        },
        {
          icon: process.env.BASE_URL + "icons/icon-phone.png",
          qr: process.env.BASE_URL + "qr/phone.png",
        },
      ],
      filingIcon: process.env.BASE_URL + "icons/filing.png",
    };
  },
  computed: {
    ...mapState({
      settingLang: (state) => state.lang,
      FooterSetting: (state) => state.langSetting.FooterSetting,
      FooterMenus: (state) => state.menus.FooterMenus,
      bread: (state) => state.bread,
    }),
  },
  methods: {
    changeRouter(menu, index) {
      //修改头部菜单索引缓存，用于刷新
      localStorage.setItem("headerMenuIndex", index + 1);

      // this.$nextTick(()=>{
      //   //欺骗式写法，修改头部菜单样式达到菜单切换效果
      //   let menu_active = document.getElementsByClassName("menu-active");
      //   let menu_button = document.getElementsByClassName("menu-button");
      //   menu_active&&menu_active[0]?menu_active[0].classList.remove("menu-active"):0
      //   menu_button[index+1].classList.add("menu-active")
      // })

      //交通指南是同一个页面，在这里互相区分,衔接锚点
      if (menu.path === "traffic") {
        this.resetBread();
        localStorage.setItem("trafficType", menu.type);
        this.goAnchor(menu.type);
      }
      if (menu.path.includes("http")) {
        window.open(menu.path, "_blank");
      } else {
        this.$router.push({
          name: menu.path,
          query: {},
        });
      }
    },
    resetBread() {
      let bread = this.bread;
      let lastIndex = bread.length - 1;
      bread[lastIndex].zh = "交通指南";
      bread[lastIndex].en = "Traffic";
      bread[lastIndex].breadcrumbName =
        this.settingLang === "zh" ? "交通指南" : "Traffic";
      this.$store.commit("setBread", bread);
    },
    //移动到锚点
    goAnchor(type) {
      this.$nextTick(() => {
        let head = document.getElementsByClassName("header-bottom");
        let box = document.getElementsByClassName(type);
        if (box && box[0] && box[0].offsetTop) {
          // 获取需要滚动的距离
          let box_top = box[0].offsetTop;
          let head_height = head[0].offsetHeight;
          let move_height = box_top - head_height;
          // Chrome
          document.body.scrollTop = move_height;
          // Firefox
          document.documentElement.scrollTop = move_height;
          // Safari
          window.pageYOffset = move_height;
        } else {
          //在页面中放置初次加载的锚点移动
          console.log("交通指南页面初次加载，header取不到元素");
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.footer {
  width: 100%;
  background: #1f1f1f;
  position: relative;
  z-index: 10;
}
.footer-information {
  min-height: 440px;
  // padding: 0 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  &-top {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.75rem 0;
    border-bottom: 1px solid #e4e4e4;

    .left-info {
      display: flex;
      align-items: center;
    }
    .right-button {
      display: flex;

      .button-box {
        margin-left: 2.5rem;
        cursor: pointer;
        &:hover {
          color: #bacc00;
        }
      }
    }
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 1.2rem;
    .footer-menu-box {
      margin-right: 2rem;
      display: flex;
      flex-direction: column;
      &:last-child {
        margin-right: 0;
      }
      .title {
        font-size: 1.2rem;
        margin-bottom: 1.25rem;
      }
      .menu {
        color: #aaa;
        font-size: 0.8rem;
        margin-bottom: 0.8rem;
        padding-left: 5px;
        cursor: pointer;
        &:hover {
          color: #bacc00;
        }
      }
    }
  }
}
.version {
  height: 70px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
}

.QR-test {
  height: 120px;
  width: 120px;
}
</style>
