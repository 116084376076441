<template>
  <div class="page">
    <!-- 页面头部 包含面包屑 -->
    <PageHead
      :headImg="exampleImg"
      :subTitle="subTitle"
      :subTitleEn="subTitleEn"
    />

    <div class="page-body flex flex-direction align-center">
      <div class="flex body-map">
        <div style="width: 77%">
          <Map
            :center="{
              lng: 121.444427,
              lat: 29.671017,
            }"
            ref="mapRef"
            :zoom="15"
            height="456"
          />
        </div>
        <div
          class="flex flex-direction justify-between map-phones"
          style="height: 456px; overflow: auto"
        >
          <!-- <span class="flex phone-title">{{ Info.scenic_name }} </span> -->
          <div class="flex flex-direction text-bold">
            <span class="phone-text">详细地址</span>
            <span class="phone-text">{{ Info.add }}</span>
          </div>
          <div class="flex flex-direction text-bold">
            <span class="phone-text">开放时间</span>
            <span class="phone-text">{{
              Info.open_time || "上午08:30-下午16:30"
            }}</span>
          </div>
          <div class="flex flex-direction">
            <span class="phone-text">咨询电话</span>
            <span class="phone-text">{{ phone[0] || "0574-88123456" }}</span>
          </div>
          <div class="flex flex-direction">
            <span class="phone-text">投诉电话</span>
            <span class="phone-text">{{ phone[1] || "0574-88123456" }}</span>
          </div>
          <div class="flex flex-direction">
            <span class="phone-text">救援电话</span>
            <span class="phone-text">{{ phone[2] || "0574-88123456" }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHead from "@/components/page-header/page-head";
import Map from "@/components/bmap/bmap";
import {
  CustomerServiceOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons-vue";

export default {
  name: "questions",
  components: {
    PageHead,
    CustomerServiceOutlined,
    PhoneOutlined,
    EnvironmentOutlined,
    Map,
  },
  mounted() {
    this.getData();
    this.getConfig();
  },
  data() {
    return {
      Info: {},
      phone: "",
    };
  },
  methods: {
    async getConfig() {
      let data = await this.$http.Index_getOpenSystemConfig();
      this.phone = [
        data.zixun_dianhua,
        data.toushu_dianhua,
        data.jiuyuan_dianhua,
      ];
    },
    async getData() {
      let res = await this.$http.Scenic_query({
        id: 1,
      });
      let scenic_data = res.data[0];
      scenic_data.add =
        scenic_data.province +
        scenic_data.city +
        scenic_data.area +
        scenic_data.street;
      this.Info = scenic_data;
      let time = setTimeout(() => {
        this.$refs.mapRef.toCenter({
          lng: scenic_data.map_lng,
          lat: scenic_data.map_lat,
        });
        clearTimeout(time);
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
@green: #22915b;

.page {
  width: 100%;
  font-weight: 400;
  background: #f5f5f5;
}

.head-img {
  height: 400px;
  width: 100%;
  position: relative;

  .background-image {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: inherit;
  }

  .info-box {
    width: 100%;
    height: 100%;
    position: absolute;
    color: #ffffff;
    z-index: 2;

    .info-box-title {
      font-size: 70px;
      // font-family: FZZhengHeiS-EB-GB;
      // line-height: 26px;
      text-shadow: 0px 1px 5px rgba(27, 27, 27, 0.84);
    }

    .info-box-line {
      width: 55px;
      // height: 5px;
      border: 3px solid #ffffff;
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
    }

    .info-box-des {
      font-size: 22px;
      font-family: Microsoft YaHei;
    }
  }
}

.page-body {
  padding: 2.5rem 18.75%;
  background: #ffffff;

  .body-title {
    font-size: 35px;
    font-weight: 400;
    color: #021211;
    padding-bottom: 1.5rem;
  }

  .body-contact {
    width: 100%;
    background: #f5f5f5;
    padding: 1rem;

    .contact-item {
      display: flex;
      flex-shrink: 1;
      align-items: center;
      width: 32.3%;
      background: #ffffff;
      padding: 1rem;

      .item-icon {
        margin-right: 1rem;
        // color: #22915b;
      }

      .contact-word {
        font-size: 20px;
        color: #666666;
      }

      .contact-phone {
        font-size: 20px;
        color: #212121;
      }
    }
  }

  .body-qr {
    width: 100%;
    padding: 3rem 0;

    .qr-icon {
      padding-top: 1rem;
      .icon-name {
        padding-left: 0.5rem;
        font-size: 24px;
        font-weight: 400;
        color: #666666;
      }
    }
  }

  .body-map {
    width: 100%;
    margin-bottom: 3rem;
    border: 1px solid #eeeeee;
    box-shadow: 0px 4px 16px 0px rgba(209, 209, 209, 0.8);

    .map-phones {
      flex: 1;
      padding: 1.5rem;

      .phone-title {
        font-size: 28px;
        color: #021211;
      }

      .phone-text {
        font-size: 20px;
        color: #212121;
        word-break: break-all;
      }
    }
  }
}
.text-bold {
  font-weight: bold;
}
</style>
